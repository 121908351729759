<template>
  <div id="orders-details">
    <promises-orders-details-list :promises="promises"></promises-orders-details-list>
  </div>
</template>

<script>
  import PromisesOrdersDetailsList from './components/PromisesOrdersDetailsList'

  export default{
    name: 'OrdersDetails',
    components: {PromisesOrdersDetailsList},
    props: ['promises'],
    data() {
      return {
      }
    },
  }
</script>
