<template>
    <div>
        <b-card>
            <div class="my-2">
                <b-row class="mx-2">
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Nombre</h4>
                        <h6 class="font-weight-normal">{{general.quotation.customer.name}} {{general.quotation.customer.last_name}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Correo</h4>
                        <h6 class="font-weight-normal">{{general.quotation.customer.email}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">R.U.N.</h4>
                        <h6 class="font-weight-normal">{{general.quotation.customer.rut}}-{{general.quotation.customer.rut_dv}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Teléfono</h4>
                        <h6 class="font-weight-normal">{{general.quotation.customer.phone}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Estado</h4>
                        <b-badge pill :variant="_getStatusColor(general.status)">
                            {{ _getStatus(general.status) }}
                        </b-badge>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Fecha</h4>
                        <h6 class="font-weight-normal">{{general.created_at | moment("DD-MM-YYYY")}}</h6>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
export default{
    name: 'PromisesOrders',
    props: ['general'],
    data() {
        return {

        }
    },
    methods: {
        _getStatusColor(status){
            switch (status) {
                case 'paid':
                    return 'light-success'
                case 'canceled':
                    return 'light-danger'
                case 'pending':
                    return 'light-warning'
            }
        },
        _getStatus(status){
            switch (status) {
                case 'paid':
                    return 'Pagada'
                case 'canceled':
                    return 'Cancelada'
                case 'pending':
                    return 'Pendiente'
            }
        }
    }
}
</script>