<template>
    <div>
        <b-card v-for="(payment, index) in payments" :key="index" class="mb-2">
            <b-row class="mx-2 my-2">
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Orden de pago</h4>
                    <h6 class="font-weight-normal">#{{payment.flow_order}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Divisa</h4>
                    <h6 class="font-weight-normal">{{payment.flow_currency}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Monto transacción</h4>
                    <h6 class="font-weight-normal">${{numberPrice(payment.transaction_amount)}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Medio</h4>
                    <h6 class="font-weight-normal">{{payment.flow_media}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Orden</h4>
                    <h6 class="font-weight-normal">{{payment.flow_commerce_order}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Estado</h4>
                    <h6 class="font-weight-normal">{{payment.status === 'sended' || payment.status === 'charged' ? 'Pagada' : 'Rechazada'}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Email</h4>
                    <h6 class="font-weight-normal">{{payment.email}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Fecha transacción</h4>
                    <h6 class="font-weight-normal">{{payment.created_at | moment("DD-MM-YYYY")}}</h6>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
    export default{
        name: 'PromiseOrderDetailPayment',
        props: ['payments'],
        data() {
            return {
            }
        },
        methods:{
            numberPrice(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
        }
    }
</script>