<template>
    <div>
        <b-tabs pills vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        nav-class="nav-left"
        nav-wrapper-class="col-md-3 col-12">
            <b-tab active>
                <template #title>
                <feather-icon
                    icon="UserIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">General</span>
                </template>
                <promise-order-detail-general :general="promises"></promise-order-detail-general>
            </b-tab>
            <b-tab v-if="promises.quotation.propertyquotation[0].property != null">
                <template #title>
                <feather-icon
                    icon="BoxIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">Propiedad</span>
                </template>
                <promise-order-detail-property :property="promises.quotation.propertyquotation[0].property"></promise-order-detail-property>
            </b-tab>
            <b-tab v-if="promises.quotation.propertyquotation[0].warehouse != null">
                <template #title>
                <feather-icon
                    icon="BoxIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">Bodega</span>
                </template>
                <promise-order-detail-warehouse :warehouse="promises.quotation.propertyquotation[0].warehouse"></promise-order-detail-warehouse>
            </b-tab>
            <b-tab v-if="promises.quotation.propertyquotation[0].parkinglot != null">
                <template #title>
                <feather-icon
                    icon="TruckIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">Estacionamiento</span>
                </template>
                <promise-order-detail-parkinglot :parkinglot="promises.quotation.propertyquotation[0].parkinglot"></promise-order-detail-parkinglot>
            </b-tab>
            <b-tab v-if="promises.payments.length > 0">
                <template #title>
                <feather-icon
                    icon="DollarSignIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">Pago</span>
                </template>
                <promise-order-detail-payment :payments="promises.payments"></promise-order-detail-payment>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import PromiseOrderDetailGeneral from './details/order/PromiseOrderDetailGeneral'
    import PromiseOrderDetailProperty from './details/order/PromiseOrderDetailProperty'
    import PromiseOrderDetailParkinglot from './details/order/PromiseOrderDetailParkinglot'
    import PromiseOrderDetailWarehouse from './details/order/PromiseOrderDetailWarehouse'
    import PromiseOrderDetailPayment from './details/order/PromiseOrderDetailPayment'

    export default{
        name: 'PromisesOrdersDetailsList',
        props: ['promises'],
        components:{
            PromiseOrderDetailGeneral,
            PromiseOrderDetailProperty,
            PromiseOrderDetailParkinglot,
            PromiseOrderDetailWarehouse,
            PromiseOrderDetailPayment
        },
        data() {
            return {
            }
        },
    }
</script>